<template lang="pug">
  .layout
    v-dialog(fill-height fluid v-model="showModal" scrollable transition="fade-transition" persistent max-width="600px" :fullscreen="$vuetify.breakpoint.xsOnly")
      v-card(min-height="80vh")
        v-card-title.px-5(:style="`background-color: ${$vuetify.theme.themes[$theme].basic100}`")
          v-row(no-gutters align="center")
            v-col.shrink
              v-btn.ml-n3(text icon @click="showModal = !showModal" height)
                eva-icon(name="arrow-back-outline" :fill="$vuetify.theme.themes[$theme].basic800" width="24" height="24")
            v-col.ml-3
              p.mb-0.h6.basic800--text {{ $t('add_items') }}
        v-card-text.pa-0
          .products
            .background-header
              v-container.white.px-4.pb-4.pt-0
                v-text-field(v-model="searchText" :placeholder="$t('search_product')" hide-details dense outlined height="40" background-color="basic200")
                  template(v-slot:append)
                    eva-icon(name="search-outline" :fill="$vuetify.theme.themes[$theme].basic600")
              v-container.px-4.pt-0.pb-6
                v-layout.my-6(v-if="isFetching")
                  v-row.fill-height.ma-0(align='center' justify='center')
                    v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
                .contain-layout(v-else)
                  v-layout.my-6(v-if="products.length == 0")
                    v-row.fill-height.ma-0(align='center' justify='center')
                      div.mx-auto
                        v-img.mx-auto(:src="require('@/assets/images/icon_empty.png')" style="width:80px; height:80px" contain)
                        p.caption {{ $t('products_not_found') }}
                  .items-layout(v-else)
                    template(v-for='(product, index) in products')
                      product-item(:product="product")
                      v-divider.basic300
                y-pagination.ma-4(v-model="page" :pageSize="pageSize" :count="count")

        v-card-actions.pa-4
          v-btn.button-AA-small(color="success500" block dark depressed @click="clickSave()" :loading="isLoading") {{ $t('save_order') }}

</template>

<script>
import { mapGetters } from 'vuex'
import ProductItem from '@/components/cart/ProductItem.vue'

export default {
  name: 'AddProductsDialog',
  components: { ProductItem },
  props: ['open'],
  data: () => ({
    isLoading: false,
    isFetching: false,
    pageSize: 10,
    count: 0,
    page: 1,
    products: [],
    searchText: '',
    searchCountDown: null,
    categories: [],
    categoryParams: ''
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getTmpCart',
      finalProducts: 'cart/getFinalProducts',
      lead: 'current/getLead',
      dataBin: 'current/getDataBin'
    }),
    showModal: {
      get() {
        return this.open
      },
      set(newValue) {
        this.$emit('update:open', newValue)
      }
    },
    form () {
      if (this.lead) {
        return this.lead.form
      } else if (this.dataBin) {
        return this.dataBin.form
      }
      return null
    }
  },
  watch: {
    page (val) {
      if (!this.isFetching) {
        this.fetchProducts(val)
      }
    },
    searchText () {
      if (this.searchCountDown) {
        clearTimeout(this.searchCountDown)
      }
      this.searchCountDown = setTimeout(() => {
        if (this.page === 1) {
          this.fetchProducts(1)
        }
        this.page = 1
      }, 500)
    },
  },
  mounted () {
    if (this.form.type === 'store') {
      if (!this.$route.query.page) {
        this.fetchCategories(1)
      } else {
        this.fetchCategories(this.$route.query.page)
      }
    } else {
      if (!this.$route.query.page) {
        this.fetchProducts(1)
      } else {
        this.fetchProducts(this.$route.query.page)
      }
    }
  },
  methods: {
    fetchCategories (page) {
      this.isFetching = true
      this.$axios.get(`/product_categories/?page_size=-1&company=${this.form.company.id}&enabled=true&ordering=-id`)
        .then((resp) => {
          if (resp.data.results && resp.data.results.length > 0) {
            this.categories = resp.data.results.filter(f => f.total_products > 0)
          }
        })
        .catch(() => {})
        .finally(() => {
          this.categories.forEach((item) => {
            this.categoryParams += `&categories=${item.id}`
          })
          this.fetchProducts(page)
        })
    },
    fetchProducts(page) {
      this.page = parseInt(page)
      this.isFetching = true
      let url = `/products/?page=${page}&page_size=${this.pageSize}&company=${this.form.company.id}&ordering=-id&status=published&bundle=false`
      if (this.form.type === 'store') {
        url += `${this.categoryParams}`
      } else {
        url += `&form=${this.form.id}`
      }
      if (this.searchText) {
        url += `&search=${this.searchText}`
      }
      this.$axios.get(url).then((response) => {
        this.isFetching = false
        this.products = [...response.data.results]
        this.count = response.data.count
      }).catch(() => {})
    },
    clickSave() {
      if (this.$store.state.cart.tmpCheckout) {
        this.$store.commit('cart/updateLeadCart', { cart: this.cart, pid: this.$store.state.cart.leadPid})
        this.setCartFromLead(this.cart)
        this.showModal = false
        return
      }
      this.isLoading = true
      const params = {
        products: this.finalProducts,
      }
      this.axios.patch(`/leads/${this.$store.state.cart.leadPid}/`, params)
        .then((resp) => {
          this.$store.commit('message/showMessage', ['success', 'Product updated!'])
          this.$store.commit('cart/updateLeadCart', { cart: resp.data.products, pid: this.$store.state.cart.leadPid})
          this.setCartFromLead(resp.data.products)
          this.showModal = false

        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async setCartFromLead (products) {
      const cart = []
      await this.asyncForEach(products, async (data) => {
        try {
          const resp = await this.$axios.get(`/products/${data.product.id}`)
          const product = resp.data
          if (!product.image && data.image) {
            product.image = data.image 
          }
          if (product.variant_for) {
            const resp2 = await this.$axios.get(`/products/${product.variant_for}`)
            const parentProduct = resp2.data
            if (!product.image && resp2.data.image) {
              product.image = resp2.data.image
            }
            product.parent_product = parentProduct
          }
          data.product = { ...product }
          cart.push(data)
          this.$store.commit('cart/updateCart', cart)
        } catch (error) {
          console.log(error)
        }
      })
    }
  }
}
</script>

<style scoped>
.product-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.product-description {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}
</style>

<i18n>
{
  "en": {
    "item": "No item | {n} item |  {n} items",
    "cancel": "Cancel",
    "confirm": "Confirm"
  }
}
</i18n>
