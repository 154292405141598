<template lang="pug">
  .text-layout.clickable(@click.stop="updateShowMore(!expand)")
    div.c1.basic600--text(v-if="html" :style="textStyle" v-html="displayText")
    div.c1.basic600--text(v-else :style="textStyle") {{ displayText }}
    .toggle(v-if="text.length > max || shouldShowMore")
      v-btn.pa-0(text color="transparent" :ripple="false" small link @click.stop="updateShowMore(!expand)")
        .p2.basic600--text.text-capitalize| {{!expand ? $t('show_more') : $t('show_less')}}
        eva-icon.mr-n1(:name="expand ? 'chevron-up' : 'chevron-down'" :fill="$vuetify.theme.themes[$theme].basic600" height="20")

</template>

<script>
export default {
  name: 'ShowMoreText',
  props: ['text', 'max', 'html'],
  data: () => ({
    expand: false,
  }),
  computed: {
    textStyle () {
      if (this.expand) {
        return 'white-space: pre-wrap;'
      }
      return ''
    },
    displayText () {
      if (this.expand) {
        return this.text
      }
      if (this.text.length > this.max) {
        return `${this.text.substring(0, this.max - 1)}...`
      }
      return this.text
    },
    shouldShowMore () {
      let lineBreakCount = this.text.split(/\r\n|\r|\n/).length
      return lineBreakCount > 2
    }
  },
  methods: {
    updateShowMore (state) {
      this.expand = state
    },
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
