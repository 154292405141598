<template lang="pug">
  .product-item
    v-row(no-gutters)
      v-col.shrink.py-3
        v-img(:src="product.image ? `https://img.yezza.io/120x120/${product.image.path}` : require('@/assets/images/image_placeholder.png')" max-width="56px" width="56px" height="56px")
      v-col.py-3.pl-2
        div
          div.p2.primary--text {{ product.name | capitalize }}
          show-more-text.c1.basic600--text.mt-1(v-if="product.description && product.description.length > 60" :text="product.description" max=60 style="line-height: 1.2em;  min-height:48px;")
          div.c1.basic600--text.mt-1(v-else) {{ product.description | capitalize }}
        div.d-flex.align-center
          div.p2.primary--text {{ product.price | displayPrice($currency) }}
          v-spacer
          div.d-flex
            v-btn.my-auto(icon small @click="clickMinusQuantity(product)" :disabled="getQuantity < 1")
              eva-icon.mt-1(name="minus-circle" height="24" width="24" :fill="getQuantity < 1 ? $vuetify.theme.themes[$theme].textColorDisabled : $vuetify.theme.themes[$theme].success600")
            v-layout.divbox.subtitle-1.my-auto.text-center.mx-2(style="width: 100%") 
              .s1.ma-auto {{ getQuantity }}
            v-btn.my-auto(icon small @click="clickAddQuantity(product)" :disabled="checkOutOfStock(product)")
              eva-icon.mt-1(name="plus-circle" height="24" width="24" :fill="checkOutOfStock(product) ? $vuetify.theme.themes[$theme].textColorDisabled : $vuetify.theme.themes[$theme].success600")

    .product-options(style="max-width:500px")
      variants(v-if="openVariants" :open.sync="openVariants" :product="product")

    .product-addons(style="max-width:500px")
      addons(v-if="openAddons" :open.sync="openAddons" :product="product" :isMultipleAddon="true" :isBulkUpdate="true")

</template>

<script>
import { mapGetters } from 'vuex'
import Product from '@/components/cart/Product.vue'
import Variants from '@/components/cart/Variants.vue'
import Addons from '@/components/cart/Addons.vue'
import ShowMoreText from '@/components/ShowMoreText'

export default {
  name: 'ProductItem',
  components: { Product, Variants, Addons, ShowMoreText },
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    showAddProductDialog: false,
    openVariants: false,
    openAddons: false,
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getTmpCart'
    }),
    getQuantity () {
      if (this.product.has_variants) {
        return this.cart.filter(f => f.product.variant_for === this.product.id).map(m => (m.quantity)).reduce((a, b) => a + b, 0)
      }
      return this.cart.filter(f => f.product.id === this.product.id).map(m => (m.quantity)).reduce((a, b) => a + b, 0)
    },
  },
  methods: {
    clickMinusQuantity (product) {
      if (product.has_variants) {
        this.openVariants = true
      } else {
        if (product.has_addons) {
          this.openAddons = true
        } else {
          this.updateSingleProduct(this.getQuantity - 1)
        }
      }
    },
    checkOutOfStock (product) {
      if (product.has_variants || product.has_addons) {
        return false
      }
      let totalCount = this.cart.filter(f => f.product.id === product.id).map(m => (m.quantity)).reduce((a, b) => a + b, 0)
      if (this.cart.filter(f => f.product.has_bundles).length > 0) {
        this.cart.filter(f => f.product.has_bundles).forEach(o => {
          if (o.bundles.filter(f => f.product.id === product.id)) {
            totalCount++
          }
        })
      }
      if (product.inventory_enabled && product.order_limit_enabled) {
        if (product.inventory_qty <= totalCount) {
          return true
        }
        if (product.order_limit <= totalCount) {
          return true
        }
      } else if (!product.order_limit_enabled && product.inventory_enabled && product.inventory_qty <= totalCount) {
        return true
      } else if (!product.inventory_enabled && product.order_limit_enabled && product.order_limit <= totalCount) {
        return true
      }
      return false
    },
    clickAddQuantity (product) {
      if (product.has_variants) {
        this.openVariants = true
      } else if (product.has_addons) {
        this.openAddons = true
      } else {
        this.updateSingleProduct(this.getQuantity + 1)
      }
    },
    updateSingleProduct (quantity) {
      let item = this.cart.filter(f => f.product.id === this.product.id)[0]
      if (!item) {
        item = {
          product: this.product
        }
      }
      const newItem = { ...item }
      newItem.quantity = quantity
      this.$store.commit('cart/updateItem', newItem)
    }
  }
}
</script>

<style scoped>
.divbox {
  border: 2px solid var(--v-basic500);
  background-color: var(--v-basic100);
  height: 32px;
  max-width: 40px;
  min-width: 40px;
  border-radius: 5px;
}
</style>
