<template lang="pug">
  .variant-item
    div.d-flex.align-center
      div
        div.p1.primary--text {{ variant.name }}
        div.p2.basic600--text {{ variant.price | displayPrice($currency) }}
      v-spacer
      div.d-flex.justify-center
        v-btn.my-auto(icon small @click="variant.quantity--" :disabled="variant.quantity < 1")
          eva-icon.mt-1(name="minus-circle" height="24" width="24" :fill="variant.quantity < 1 ? $vuetify.theme.themes[$theme].textColorDisabled : $vuetify.theme.themes[$theme].success600")
        v-layout.divbox.subtitle-1.my-auto.text-center.mx-4(style="width: 100%") 
          .s1.ma-auto {{ variant.quantity }}
        v-btn.my-auto(icon small @click="variant.quantity++" :disabled="isOutOfStock(cart, variant) || parentOutOfStock")
          eva-icon.mt-1(name="plus-circle" height="24" width="24" :fill="(isOutOfStock(cart, variant) || parentOutOfStock) ? $vuetify.theme.themes[$theme].textColorDisabled : $vuetify.theme.themes[$theme].success600")
          
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VariantItem',
  props: {
    variant: {
      type: Object,
      default: null
    },
    parentOutOfStock: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getTmpCart'
    })
  },
  watch: {
    variant: {
      deep: true,
      handler () {
        this.updateLeadCart()
      }
    }
  },
  methods: {
    updateLeadCart () {
      let item = this.cart.filter(f => f.product.id === this.variant.id)[0]
      if (!item) {
        item = {
          product: this.variant
        }
      }
      const newItem = { ...item }
      newItem.quantity = this.variant.quantity
      this.$store.commit('cart/updateItem', newItem)
    }
  }
}
</script>

<style scoped>
.divbox {
  border: 2px solid var(--v-basic500);
  background-color: var(--v-basic100);
  height: 32px;
  max-width: 50px;
  min-width: 50px;
  border-radius: 5px;
}
</style>
