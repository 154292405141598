<template lang="pug">
  div
    v-bottom-sheet(v-model="productSheet" max-width="600px" scrollable)
      v-card(flat)
        v-card-text.pa-0(style="color: rgba(0, 0, 0, 1);")
          v-layout.my-6(v-if="isFetching")
            v-row.fill-height.ma-0(align='center' justify='center')
              v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
          div.pb-2(v-else)
            div.pa-4.d-flex
              div.pr-1
                div.s1.primary--text {{ selectedVariant.quantity && selectedVariant.quantity >= 1 ? selectedVariant.quantity + ' x ' + product.name : product.name }}
                div.c1(v-if="selectedVariant.id") {{ selectedVariant.name }}
              v-spacer
              div.pl-1(align="right")
                div.s1(v-if="selectedVariant.id") {{ (selectedVariant.price * selectedVariant.quantity) | displayPrice($currency) }}
                div.s1(v-else-if="priceRange.length > 1") {{ priceRange[0] | displayPrice($currency) }} - {{ priceRange[1] | displayPrice($currency) }}
                div.s1(v-else) {{ priceRange[0] | displayPrice($currency) }}
            div.px-4
              template(v-for="option in options")
                div.mb-4(:key="option.id")
                  div.label.basic600--text {{ option.name }}
                  template(v-for="spec in option.specs")
                    v-btn.mr-2.mt-2.button-Aa-tiny(:color="checkSelected(selectedVariant, option.id, spec.id) ? 'teal' : 'grey lighten-3'" :outlined="checkSelected(selectedVariant, option.id, spec.id)" :disabled="specDisabled(selectedVariant, option.id, spec.id)" small depressed height="24" @click="clickSpec(selectedVariant, option, spec)" :key="spec.id") {{ spec.value }}
              div.d-flex.justify-center
                //- v-btn.my-auto(v-if="selectedVariant.quantity < 2" icon small @click="selectedVariant.quantity--; clickSave()")
                  eva-icon(name="trash-2-outline" height="24" width="24" :fill="$vuetify.theme.themes[$theme].danger500" size="28") mdi-delete-outline
                v-btn.my-auto(icon small @click="selectedVariant.quantity--" :disabled="selectedVariant.quantity < 1")
                  eva-icon.mt-1(name="minus-circle" height="24" width="24" :fill="selectedVariant.quantity < 1 ? $vuetify.theme.themes[$theme].textColorDisabled : $vuetify.theme.themes[$theme].success600")
                v-layout.divbox.subtitle-1.my-auto.text-center.mx-4(style="width: 100%") 
                  .s1.ma-auto {{ selectedVariant.quantity }}
                v-btn.my-auto(icon small @click="selectedVariant.quantity++" :disabled="addQtyDisabled(selectedVariant) || parentOutOfStock")
                  eva-icon.mt-1(name="plus-circle" height="24" width="24" :fill="(addQtyDisabled(selectedVariant) || parentOutOfStock) ? $vuetify.theme.themes[$theme].textColorDisabled : $vuetify.theme.themes[$theme].success600")

        v-card-actions.py-4
          v-btn.mr-2(v-if="selectedVariant.quantity == 0" color='danger500' depressed dark block @click="clickSave()" :loading="isLoading")
            .button-AA-medium.basic100--text {{ $t('remove_item') }}
          v-btn.mr-2(v-else color='success500' depressed dark block @click="clickSave()" :loading="isLoading")
            .button-AA-medium.basic100--text {{ $t('update_cart') }}

</template>

<script>

import { mapGetters } from 'vuex'
import VariantItem from '@/components/cart/VariantItem.vue'

export default {
  name: 'Variants',
  components: { VariantItem },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    isLoading: false,
    isFetching: false,
    variants: [],
    options: [],
    selectedVariant: {
      id: null,
      name: null,
      quantity: 0,
      price: null,
      selectedOptions: [],
    },
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getTmpCart',
      finalProducts: 'cart/getFinalProducts'
    }),
    productSheet: {
      get () {
        return this.open
      },
      set (newValue) {
        this.$emit('update:open', newValue)
      }
    },
    parentOutOfStock() {
      let totalCount = this.cart.filter(f => f.product.variant_for === this.product.id).map(m => (m.quantity)).reduce((a, b) => a + b, 0) + this.selectedVariant.quantity
      if (this.product.inventory_enabled && this.product.order_limit_enabled) {
        if (this.product.inventory_qty <= totalCount) {
          return true
        }
        if (this.product.order_limit <= totalCount) {
          return true
        }
      } else if (!this.product.order_limit_enabled && this.product.inventory_enabled && this.product.inventory_qty <= totalCount) {
        return true
      } else if (!this.product.inventory_enabled && this.product.order_limit_enabled && this.product.order_limit <= totalCount) {
        return true
      }
      return false
    },
    priceRange () {
      if (this.variants.length > 0) {
        const nums = this.variants.map(m => m.product.price).slice().sort((a, b) => {
          return a - b
        })
        if (nums[0] === nums[nums.length - 1]) {
          return [nums[0]]
        }
        return [nums[0], nums[nums.length - 1]]
      }
      return [0]
    },
  },
  mounted () {
    this.fetchVariants()
  },
  methods: {
    fetchVariants () {
      this.isFetching = true
      this.$axios.get(`/products/${this.product.id}?expand=variants,options`)
        .then((resp) => {
          this.variants = resp.data.variants
          this.options = resp.data.options
        })
        .catch(() => {
          this.$store.commit('message/updateMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    clickSpec (variant, option, spec) {
      if (variant.selectedOptions.filter(f => f.id === option.id).length > 0) {
        if (variant.selectedOptions.filter(f => f.id === option.id)[0].selected === spec.id) {
          variant.selectedOptions = variant.selectedOptions.filter(f => f.id !== option.id)
          variant.id = null
          variant.name = null
          variant.price = null
          variant.quantity = 0
          return
        }
        variant.selectedOptions.filter(f => f.id === option.id)[0].selected = spec.id
      } else {
        variant.selectedOptions.push({ id: option.id, name: option.name, selected: spec.id })
      }
      this.selectVariant(variant)
    },
    selectVariant (variant) {
      if (this.options.length > 1) {
        if (variant.selectedOptions.filter(f => f.selected).length > 1) {
          const filteredVariants = this.variants.filter(f => variant.selectedOptions.map(m => m.selected).every(e => f.specs.includes(e)))
          if (filteredVariants.length > 0) {
            const variantData = this.variants.filter(f => variant.selectedOptions.map(m => m.selected).every(e => f.specs.includes(e)))[0]
            if (!variantData.product.inventory_enabled || (variantData.product.inventory_enabled && variantData.product.inventory_qty > 0)) {
              variant.id = variantData.product.id
              variant.name = variantData.product.name
              variant.price = variantData.product.price
              if (variant.quantity === 0 && !this.parentOutOfStock) {
                variant.quantity = 1
              }
            }
          }
        }
      } else {
        const variantData = this.variants.filter(f => f.specs.includes(variant.selectedOptions[0].selected))[0]
        if (!variantData.product.inventory_enabled || (variantData.product.inventory_enabled && variantData.product.inventory_qty > 0)) {
          variant.id = variantData.product.id
          variant.name = variantData.product.name
          variant.price = variantData.product.price
          if (variant.quantity === 0 && !this.parentOutOfStock) {
            variant.quantity = 1
          }
        }
      }
    },
    specDisabled (variant, optionId, specId) {
      if (this.options.length > 1) {
        if (variant.selectedOptions.length > 0) {
          if (variant.selectedOptions.length === 1) {
            if (variant.selectedOptions[0].id !== optionId) {
              const filteredVariant = this.variants.filter(f => f.specs.includes(specId) && f.specs.includes(variant.selectedOptions[0].selected))[0].product
              const totalSpecQty = this.cart.filter(f => f.product.id === filteredVariant.id).map(m => m.quantity).reduce((a, b) => a + b, 0)
              if (variant.id !== filteredVariant.id) {
                if (filteredVariant.inventory_enabled && filteredVariant.inventory_qty <= totalSpecQty) {
                  return true
                } else if (filteredVariant.inventory_enabled && (filteredVariant.inventory_qty - totalSpecQty) < variant.quantity) {
                  return true
                } else if (filteredVariant.order_limit_enabled && filteredVariant.order_limit <= totalSpecQty) {
                  return true
                } else if (filteredVariant.order_limit_enabled && (filteredVariant.order_limit - totalSpecQty) < variant.quantity) {
                  return true
                }
              }
            }
            return false
          } else {
            if (!variant.selectedOptions.map(m => m.selected).includes(specId)) {
              const otherSpecId = variant.selectedOptions.filter(f => f.id !== optionId)[0].selected
              const filteredVariant = this.variants.filter(f => f.specs.includes(specId) && f.specs.includes(otherSpecId))[0].product
              const totalSpecQty = this.cart.filter(f => f.product.id === filteredVariant.id).map(m => m.quantity).reduce((a, b) => a + b, 0)
              if (variant.id !== filteredVariant.id) {
                if (filteredVariant.inventory_enabled && filteredVariant.inventory_qty <= totalSpecQty) {
                  return true
                } else if (filteredVariant.inventory_enabled && (filteredVariant.inventory_qty - totalSpecQty) < variant.quantity) {
                  return true
                } else if (filteredVariant.order_limit_enabled && filteredVariant.order_limit <= totalSpecQty) {
                  return true
                } else if (filteredVariant.order_limit_enabled && (filteredVariant.order_limit - totalSpecQty) < variant.quantity) {
                  return true
                }
              }
            }
            return false
          }
        }
        return false
      } else {
        const filteredVariant = this.variants.filter(f => f.specs.includes(specId))[0].product
        const totalSpecQty = this.cart.filter(f => f.product.id === filteredVariant.id).map(m => m.quantity).reduce((a, b) => a + b, 0)
        if (variant.id !== filteredVariant.id) {
          if (filteredVariant.inventory_enabled && filteredVariant.inventory_qty <= totalSpecQty) {
            return true
          } else if (filteredVariant.inventory_enabled && (filteredVariant.inventory_qty - totalSpecQty) < variant.quantity) {
            return true
          } else if (filteredVariant.order_limit_enabled && filteredVariant.order_limit <= totalSpecQty) {
            return true
          } else if (filteredVariant.order_limit_enabled && (filteredVariant.order_limit - totalSpecQty) < variant.quantity) {
            return true
          }
        }
        return false
      }
    },
    addQtyDisabled (variant) {
      if (!variant.id) {
        return true
      }
      const filteredVariant = this.variants.filter(f => f.product.id === variant.id)[0].product
      const totalVariantQty = this.cart.filter(f => f.product.id === filteredVariant.id).map(m => m.quantity).reduce((a, b) => a + b, 0) + variant.quantity
      if (filteredVariant.inventory_enabled && filteredVariant.order_limit_enabled) {
        if (filteredVariant.inventory_qty <= totalVariantQty) {
          return true
        }
        if (filteredVariant.order_limit <= totalVariantQty) {
          return true
        }
      } else if (!filteredVariant.order_limit_enabled && filteredVariant.inventory_enabled && filteredVariant.inventory_qty <= totalVariantQty) {
        return true
      } else if (!filteredVariant.inventory_enabled && filteredVariant.order_limit_enabled && filteredVariant.order_limit <= totalVariantQty) {
        return true
      }
      return false
    },
    checkSelected (variant, optionId, specId) {
      if (variant.selectedOptions.filter(f => f.id === optionId).length > 0) {
        return variant.selectedOptions.filter(f => f.id === optionId)[0].selected === specId
      }
      return false
    },
    updateLeadCart () {
      const newItem = { 
        product: { 
          ...this.variants.find(f => f.product.id === this.selectedVariant.id).product,
          variant_for: this.product.id,
          parent_product: this.product
        },
        quantity: this.selectedVariant.quantity
      }
      this.$store.commit('cart/updateItem', newItem)
    },
    clickSave() {
      if (!this.selectedVariant.id) {
        this.productSheet = false
        return
      }
      this.updateLeadCart()
      if (this.$store.state.cart.tmpCheckout) {
        this.$store.commit('cart/updateLeadCart', { cart: this.cart, pid: this.$store.state.cart.leadPid})
        this.productSheet = false
        return
      }
      this.isLoading = true
      const params = {
        products: this.finalProducts,
      }
      this.axios.patch(`/leads/${this.$store.state.cart.leadPid}/`, params)
        .then((resp) => {
          this.$store.commit('message/showMessage', ['success', 'Product updated!'])
          this.$store.commit('cart/updateLeadCart', { cart: resp.data.products, pid: this.$store.state.cart.leadPid})
          this.productSheet = false
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.divbox {
  border: 2px solid var(--v-basic400);
  background-color: var(--v-basic200);
  height: 40px;
  max-width: 60px;
  border-radius: 5px;
}
</style>
