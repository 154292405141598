<template lang="pug">
  v-dialog(fill-height fluid v-model="showModal" scrollable transition="fade-transition" persistent max-width="600px")
    v-card(v-if="invoice.coupons.length > 0")
      v-card-title.h6.basic800--text {{ $t('remove_coupon') }}
      v-card-text.p2.primary500--text
        | {{ $t('remove_coupon_confirmation') }}
      v-card-actions
        v-spacer
        v-btn(color='white', text @click='showModal = false')
          .button-Aa-small.primary400--text {{ $t('cancel') }}
        v-btn.mr-2(color='danger500' depressed height=32 dark :loading="isLoading"  @click='startDelete')
          .button-AA-small.basic100--text {{ $t('remove') }}

    v-card(v-else)
      v-card-title
        div.h6.basic800--text {{ $t('coupon_code') }}
      v-divider.basic300
      v-card-text.pt-4.pb-0
        .coupon-code
          p.mb-1.label.basic600--text.text-uppercase {{ $t('enter_code') }}
          v-text-field.mt-2(v-model='couponCode' :placeholder="$t('write_a_valid_coupon')" background-color="basic200" height="40" outlined dense persistent-hint)

      v-card-actions
        v-spacer
        v-btn(color='white', text @click='showModal = false')
          .button-Aa-small.primary400--text {{ $t('close') }}
        v-btn.mr-2(color='textColorAccent' depressed height=32 :loading="isLoading" @click='clickApply' :dark="couponCode != '' && couponCode != null" :disabled="!couponCode")
          .button-AA-small.basic100--text {{ $t('apply') }}

</template>

<script>
export default {
  name: 'ApplyCouponDialog',
  props: ['open', 'invoice'],
  data: () => ({
    couponCode: null,
    isLoading: false
  }),
  computed: {
    showModal: {
      get() {
        return this.open
      },
      set(newValue) {
        this.$emit('update:open', newValue)
      }
    }
  },
  mounted () {
    if (this.invoice.coupons.length > 0) {
      this.couponCode = this.invoice.coupons[0].code
    }
  },
  methods: {
    startDelete () {
      this.isLoading = true
      this.$axios.post(`/invoices/${this.invoice.pid}/remove_coupon`, { code: this.couponCode })
        .then(() => {
          this.$store.commit('message/showMessage', ['success', 'Coupon code removed'])
          this.$emit('applied')
          this.showModal = false
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    clickApply () {
      this.isLoading = true
      this.$axios.post(`/invoices/${this.invoice.pid}/apply_coupon`, { code: this.couponCode })
        .then(() => {
          this.$store.commit('message/showMessage', ['success', 'Coupon code applied'])
          this.$emit('applied')
          this.showModal = false
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.detail) {
            this.$store.commit('message/showMessage', ['error', e.response.data.detail])
          } else {
            this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}
</style>
