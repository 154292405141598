<template lang="pug">
  v-card(outlined :elevation="form.flowState === $userFlows.PROCEED_PAYMENT_FLOW ? '2' : '0'")
    v-card.pa-4(flat)
      div.d-flex
        div.s2.primary--text {{ $t('order_summary') }}
        v-spacer
        v-btn.button-Aa-small.px-0(v-if="!isReadOnly" color="transparent" text @click="clickAddProducts()" small)
          div.info500--text {{ $t('add_item') }}
      div(v-for="(product, index) in cart" :key="index")
        tmp-cart-item(v-if="isTmpCheckout" :product="product")
        cart-item(v-else :product="product" :isReadOnly="isReadOnly")
    v-divider.basic300
    v-layout.my-6(v-if="isFetching")
      v-row.fill-height.ma-0(align='center' justify='center')
        v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
    template(v-else)
      v-card.pa-4(v-if="invoice && !isReadOnly" flat)
        div.d-flex.align-center
          template(v-if="invoice.coupons.length > 0" flat)
            div.button-Aa-small.basic600--text.mr-3 {{ invoice.coupons[0].code }}
          v-btn.button-Aa-small.px-0(color="transparent" text @click="openAddCouponDialog = true" small)
            div.danger500--text(v-if="invoice.coupons.length > 0") {{ $t('remove_coupon') }}
            template(v-else)
              v-icon.mr-1(color="info500" size="17") mdi-plus
              div.info500--text {{ $t('add_coupon_code') }}
      v-card.pa-4(v-else-if="invoice && isReadOnly && invoice.coupons.length > 0" flat)
        div.d-flex.align-center
          template(flat)
            div.button-Aa-small.basic600--text.mr-3 {{ invoice.coupons[0].code }}
      v-card.px-2.py-4(v-if="!isReadOnly" :color="$vuetify.theme.themes[$theme].basic600T08" flat tile)
        v-textarea.button-Aa-small.leave-message(v-model="orderMessage" :placeholder="$t('leave_message_to_seller')" rows="1" auto-grow dense flat solo background-color="transparent" hide-details="auto" spellcheck="false" @blur="checkOrderMessage")
      v-card.pa-4(flat)
        div.d-flex.py-2(v-if="subtotalAmount > 0")
          div.p2.primary--text {{ $t('subtotal') }}
          v-spacer
          div.p2.primary--text {{ subtotalAmount | displayPrice($currency) }}
        div.d-flex.py-2(v-if="shippingFeeAmount > 0")
          div.p2.primary--text {{ $t('shipping') }}
          v-spacer
          div.p2.primary--text {{ shippingFeeAmount | displayPrice($currency) }}
        div.d-flex.py-2(v-if="taxFeeAmount > 0")
          div.p2.primary--text {{ $t('tax') }}
          v-spacer
          div.p2.primary--text {{ taxFeeAmount | displayPrice($currency) }}
        div.d-flex.py-2(v-if="serviceFeeAmount > 0")
          div.p2.primary--text {{ $t('service_fee') }}
          v-spacer
          div.p2.primary--text {{ serviceFeeAmount | displayPrice($currency) }}
        div.d-flex.py-2(v-if="discountAmount > 0")
          div.p2.primary--text {{ $t('discount') }}
          v-spacer
          div.p2.danger500--text - {{ discountAmount | displayPrice($currency) }}
        div.d-flex.py-2(v-if="promoDiscountAmount > 0")
          div.p2.primary--text {{ $t('promo_discount') }}
          v-spacer
          div.p2.danger500--text - {{ promoDiscountAmount | displayPrice($currency) }}
        template(v-if="paidAmount > 0")
          div.d-flex.py-2
            div.p2.primary--text {{ $t('amount_paid') }}
            v-spacer
            div.p2.danger500--text - {{ paidAmount | displayPrice($currency) }}
          div.d-flex.py-2
            div.s1.primary--text {{ $t('balance_due') }}
            v-spacer
            div.s1.primary--text {{ balanceAmount | displayPrice($currency) }}
        div.d-flex.py-2(v-else)
          div.s1.primary--text {{ $t('total') }}
          v-spacer
          div.s1.primary--text {{ totalAmount | displayPrice($currency) }}
      
      template(v-if="showConsent")
        v-card.mb-4(:color="$vuetify.theme.themes[$theme].successT08" flat tile)
          v-row(no-gutters)
            v-col.shrink.pa-4
              y-checkbox(v-model="isConsent")
            v-col.pr-4.py-3
              div.s1.basic700--text {{ $t('checkout_faster_next') }}
              div.c1.basic700--text {{ $t('save_this_information_and_checkout') }}
      div.px-4.pb-4(v-if="order.phoneStatus === 'verified'")
        v-btn.button-AA-medium(color="info500" small height="40" block :dark="Boolean(!disablePayment)" :disabled="disablePayment" @click="clickPayNow" :loading="isLoading")
          eva-icon.mr-4(name="lock" width="16" height="16" :fill="disablePayment ? '#a6a6a6' : 'white'")
          div(:class="{ 'white--text': !disablePayment }") {{ $t('pay_now') }}
        .stock.c1.danger500--text.mt-2.text-center(v-if="outOfStock") {{ $t('not_enough_stock_available') }}
        .tnc--text.c1.basic600--text.mt-2.text-center(v-if="!disablePayment && showConsent && isConsent") {{ $t('by_clicking_pay_now_you_agree') }}

    .modals
      add-products(v-if="openAddProductsDialog" :open.sync="openAddProductsDialog")
      add-coupon(v-if="openAddCouponDialog" :open.sync="openAddCouponDialog" :invoice="invoice" @applied="fetchInvoice")
      v-dialog(v-if="showSetAmountDialog" v-model='showSetAmountDialog', max-width='320')
        v-card
          v-card-text.pa-4
            .s1.basic700--text {{ $t('minimum_deposit_of') }} {{ this.$store.state.current.order.price.minPaymentAmount | displayPrice($currency) }} {{ $t('is_allowed_for_this_purchase') }}
            v-form.mt-4(ref="form")
              p.mb-1.label.basic600--text.text-uppercase {{ $t('amount') }}
              v-text-field.mt-2(v-model='paymentAmount' placeholder="0" background-color="basic200" height="40" outlined dense type="number" :rules="priceRules" :prefix="$currency.symbol")
            v-btn.button-AA-medium.white--text(block outlined color="info500" :style="{'background-color': $vuetify.theme.themes[$theme].infoT08}" @click="clickPayPartialAmount()" dark :loading="isLoading") {{ $t('pay_this_amount') }}
            .p1.basic600--text.text-center.my-3 or
            v-btn.button-AA-medium.white--text(block color="info500" @click='proceedToPay(false)' dark :loading="isLoading")
              div(v-if="lead.status == 'partial_payment'") {{ $t('pay_remaining_amount') }}
              div(v-else) {{ $t('pay_full_amount') }}

</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CartItem from '@/components/cart/CartItem.vue'
import TmpCartItem from '@/components/cart/TmpCartItem.vue'
import AddProducts from '@/components/dialogs/AddProducts.vue'
import AddCoupon from '@/components/dialogs/AddCoupon.vue'

Vue.mixin({
  methods: {
    addOnIsOutOfStock (cart, product, addon) {
      let totalCount = 0
      if (addon.inventory_enabled) {
        const cartProducts = cart.filter(f => f.product.id === product.id)
        if (cartProducts.length <= 0) {
          return false
        }
        cartProducts.forEach(item => {
          const quantity = item.quantity
          if (item.addons) {
            const count = item.addons.filter(f => f.product.id === addon.id).map(m => (m.quantity_per_set)).reduce((a, b) => a + b, 0)
            totalCount += (quantity * count)
          }
        })
        return totalCount >= addon.inventory_qty
      }
      return false
    },
    addOnWillOutOfStock (cart, product, addon) {
      let totalCount = 0
      if (addon.inventory_enabled) {
        const cartProducts = cart.filter(f => f.product.id === product.id)
        if (cartProducts.length <= 0) {
          return false
        }
        cartProducts.forEach(item => {
          const quantity = item.quantity
          if (item.addons) {
            const count = item.addons.filter(f => f.product.id === addon.id).map(m => (m.quantity_per_set)).reduce((a, b) => a + b, 0) || 0
            if (product.product.id == item.product.id) {
              totalCount += (quantity * (count + 1))
            } else {
              totalCount += (quantity * count)
            }
          }
        })
        return totalCount > addon.inventory_qty
      }
      return false
    }
  }
})

export default {
  name: 'OrderSummary',
  props: ['isReadOnly'],
  components: { TmpCartItem, CartItem, AddProducts, AddCoupon },
  data: () => ({
    isFetching: false,
    isLoading: false,
    openAddProductsDialog: false,
    openAddCouponDialog: false,
    invoice: null,
    orderMessage: null,
    isConsent: true,
    showSetAmountDialog: false,
    payment: null,
    storeSetting: null,
  }),
  computed: {
    ...mapGetters({
      form: 'current/getForm',
      cart: 'cart/getCart',
      dataBin: 'current/getDataBin',
      lead: 'current/getLead',
      order: 'current/getOrder',
      remarks: 'current/getOrderRemarks',
      user: 'user/getUser',
      zooData: 'current/getZooData',
      orderDateTime: 'current/getDateTime',
      omsData: 'current/getOms',
    }),
    isTmpCheckout () {
      return this.$store.state.cart.tmpCheckout
    },
    subtotalAmount () {
      if (!this.invoice) return 0
      return this.invoice.subtotal_amount
    },
    taxFeeAmount () {
      if (!this.invoice) return 0
      return this.invoice.tax_amount
    },
    serviceFeeAmount () {
      if (!this.invoice) return 0
      return this.invoice.service_fee
    },
    shippingFeeAmount () {
      if (!this.invoice) return 0
      return this.invoice.shipping_fee
    },
    discountAmount() {
      if (!this.invoice) return 0
      return this.invoice.total_discount
    },
    promoDiscountAmount() {
      if (!this.invoice) return 0
      return this.invoice.total_promo_discount
    },
    paidAmount () {
      if (!this.invoice) return 0
      return this.invoice.total_paid
    },
    totalAmount () {
      if (!this.lead) {
        let total = 0
        this.cart.forEach(o => {
          let addonprice = 0
          if (o.addons) {
            o.addons.forEach(a => {
              addonprice += (a.price * a.quantity_per_set)
            })
          }
          total += (o.quantity * (o.product.price + addonprice))
        })
        return total
      }
      if (!this.invoice) return 0
      return this.invoice.total_amount
    },
    amountDue() {
      if (!this.invoice) return 0
      return this.invoice.total_amount - this.invoice.total_paid
    },
    minPaymentAmount() {
      if (!this.invoice || !this.invoice.minimum_payment) return 0
      if (this.invoice.minimum_payment > this.amountDue) {
        return this.amountDue
      }
      return this.invoice.minimum_payment
    },
    balanceAmount () {
      if (!this.invoice) return 0
      return this.invoice.total_amount - this.invoice.total_paid
    },
    outOfStock() {
      if (!this.invoice) return false
      for (let i = 0; i < this.invoice.products.length; i++) {
        let p = this.invoice.products[i]
        if (p.product.inventory_enabled && p.product.inventory_qty < p.quantity) {
          return true
        }
      }
      for (let i = 0; i < this.cart.length; i++) {
        let product = this.cart[i]
        let totalCount = 0
        const invoiceProducts = this.invoice.products.filter(f => f.product.variant_for == product.id)
        invoiceProducts.forEach((ip) => {
          totalCount += ip.quantity
        })
        if (product.computed_inventory_qty != null) {
          return product.computed_inventory_qty < totalCount
        }
      }
      return false
    },
    disablePayment () {
      if (!this.invoice) return true
      if (this.outOfStock) {
        return true
      }
      if (this.form.flowState === this.$userFlows.PROCEED_PAYMENT_FLOW) {
        return false
      }
      return true
    },
    showConsent () {
      if (!this.disablePayment && this.zooData && !this.zooData.fast_checkout_consent) {
        return true
      }
      return false
    },
    priceRules () {
      return [
        v => !!v || 'Amount is required',
        v => v != 0|| 'Amount is required',
        v => v >= this.$store.state.current.order.price.minPaymentAmount/this.$currency.multiplier || `Amount should not be below ${this.$currency.symbol} ${(parseFloat(this.$store.state.current.order.price.minPaymentAmount/this.$currency.multiplier)).toFixed(this.$currency.exponent)}`,
        v => v <= this.$store.state.current.order.price.amountDue/this.$currency.multiplier || `Amount should not be above ${this.$currency.symbol} ${(parseFloat(this.$store.state.current.order.price.amountDue/this.$currency.multiplier)).toFixed(this.$currency.exponent)}`,
      ]
    }
  },
  watch: {
    'lead.shipping' () {
      this.fetchInvoice()
    },
    cart () {
      this.fetchInvoice()
    },
    orderMessage (val) {
      this.$store.commit('current/updateOrderMessage', val)
    }
  },
  mounted ()  {
    this.$nextTick(() => {
      if (this.remarks.order_message) {
        this.orderMessage = this.remarks.order_message
      }
    })
    this.fetchInvoice()
    this.fetchStoreSettings()
    this.getProducts()
    this.getCompanySettingsList()
    if (this.zooData) {
      this.isConsent = this.zooData.fast_checkout_consent
    }
  },
  methods: {
    fetchStoreSettings () {
      if (!this.lead) {
        return
      }
      if (this.lead.form.type !== 'store') {
        return
      }
      this.$axios.get(`/store/settings/${this.lead.form.company.slug}`)
        .then(resp => {
          this.storeSetting = { ...resp.data }
        })
    },
    getCompanySettingsList () {
      if (!this.lead) {
        return
      }
      this.$axios.get(`/companies/${this.lead.form.company.slug}/settings/?use_slug=true`)
        .then((response) => {
          const thankYouSettingsName = 'yz.checkout.thankyou_message'
          const settingsList = response.data.results
          const thankYouMessageData = settingsList.find(setting => setting.name === thankYouSettingsName)
          if (thankYouMessageData) {
            this.$store.commit('current/updateCheckoutThankyouMessage', thankYouMessageData.value)
          }
        }).catch(() => {})
    },
    fetchInvoice () {
      if (!this.lead) {
        return
      }
      this.isFetching = true
      this.$axios.get(`/invoices/${this.lead.pid}/`)
        .then((response) => {
          this.invoice = response.data
          this.$store.commit('current/updateOrderPrice', { ready: true, total: this.totalAmount, amountDue: this.amountDue, minPaymentAmount: this.minPaymentAmount, paidAmount: this.paidAmount })
        })
        .catch(() => {})
        .finally(() => {
          this.isFetching = false
        })
    },
    getProducts () {
      if (this.lead) {
        this.$store.commit('cart/updateLeadCart', { cart: this.lead.products, pid: this.lead.pid })
        this.setCartProducts(this.lead.products)
      }
      if (this.dataBin && this.cart.length === 0) {
        this.$store.commit('cart/updateLeadCart', { cart: this.dataBin.products, pid: null })
        this.setCartProducts(this.dataBin.products)
      }
    },
    async setCartProducts (initialProducts) {
      const cart = []
      await this.asyncForEach(initialProducts, async (data) => {
        try {
          const resp = await this.$axios.get(`/products/${data.product.id}`)
          const product = resp.data
          if (!product.image && data.image) {
            product.image = data.image 
          }
          if (product.variant_for) {
            const resp2 = await this.$axios.get(`/products/${product.variant_for}`)
            const parentProduct = resp2.data
            if (!product.image && resp2.data.image) {
              product.image = resp2.data.image
            }
            product.parent_product = parentProduct
          }
          data.product = { ...product }
          cart.push(data)
          this.$store.commit('cart/updateCart', cart)
        } catch (error) {
          console.log(error)
        }
      })
    },
    clickAddProducts () {
      if (this.lead) {
        this.$store.commit('cart/syncTmpCart')
        this.openAddProductsDialog = true
      } else if (this.dataBin) {
        let data = JSON.parse(JSON.stringify(this.dataBin))
        data.products = JSON.parse(JSON.stringify(this.cart))
        this.$axios.put(`/databin/${data.binKey}/?ttl=300`, data)
          .then(() => {
            const url = data.previous_location.replace(data.pathname, '')
            location.href = url
          })
          .catch(() => {
            this.showMessage(['error', 'Unable to complete your request. Please try again later'])
          })
      }
    },
    checkOrderMessage () {
      if (!this.orderMessage) {
        this.orderMessage = null
      }
    },
    clickPayNow () {
      if (this.storeSetting && this.storeSetting.min_amount) {
        if (this.subtotalAmount < this.storeSetting.min_amount) {
          this.$store.commit('message/showMessage', ['error', `Amount purchase must be at least ${this.$options.filters.displayPrice(this.storeSetting.min_amount, this.$currency)}`])
          return false
        }
      } else if (this.omsData && this.omsData.minimum_order_amount_enabled) {
        if (this.omsData.minimum_order_amount && this.subtotalAmount < this.omsData.minimum_order_amount) {
          this.$store.commit('message/showMessage', ['error', `Amount purchase must be at least ${this.$options.filters.displayPrice(this.omsData.minimum_order_amount, this.$currency)}`])
          return false
        }
      }
      this.isLoading = true
      this.payment = this.order.payment
      if (this.showConsent) {
        this.$axios.patch(`/zoo/${this.user.profile}`, { fast_checkout_consent: this.isConsent })
          .then(() => {
            this.proceedToPay(false)
          })
          .catch(() => {
            this.$store.commit('message/showMessage', ['error', 'Unable to update fast checkout consent'])
            this.proceedToPay(false)
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.proceedToPay(false)
      }
    },
    clickPayPartialAmount() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.proceedToPay(true)
    },
    async proceedToPay (isPartial) {
      let params = { order_remarks: this.order.remarks }
      if (this.orderDateTime.is_ASAP !== undefined) {
        params = this.orderDateTime
        if (this.lead.shipping && this.lead.shipping.fulfilment_type === 'courier' && !this.lead.pickup) {
          params = {
            asking_delivery_date: null,
            asking_delivery_time: null,
            asking_order_type: null,
            is_ASAP: null,
          }
        }
        params.order_remarks = this.order.remarks
        await this.$axios.patch(`/leads/${this.lead.pid}/`, params)
        const validateResp = await this.$axios.get(`/validate_order/${this.lead.pid}/`)
        if (!validateResp.data.is_valid) {
          this.$store.commit('message/showMessage', ['error', validateResp.data.reason])
          this.isLoading = false
          return
        }
      } else {
        await this.$axios.patch(`/leads/${this.lead.pid}/`, params)
        const validateResp = await this.$axios.get(`/validate_order/${this.lead.pid}/`)
        if (!validateResp.data.is_valid) {
          this.$store.commit('message/showMessage', ['error', validateResp.data.reason])
          this.isLoading = false
          return
        }
      }
      if (this.payment.method === 'manual') {
        this.proceedManual()
        return
      } else if (this.payment.method === 'cod') {
        this.proceedCod()
        return
      } else if (this.payment.method === 'pas') {
        this.proceedPas()
        return
      } else if (this.lead.installment && !this.showSetAmountDialog) {
        this.isLoading = false
        this.showSetAmountDialog = true
        return
      }
      if (isPartial) {
        this.payment.data['amount'] = (parseFloat(this.paymentAmount)).toFixed(this.$currency.exponent).replace('.', '')
      } else {
        delete this.payment.data.amount
      }
      this.isLoading = true
      this.$axios.post(`/transactions/`, this.payment.data)
        .then((resp) => {
          const uid = resp.data.uid
          this.$axios.get(`/transactions/${uid}/pay/`)
            .then((resp) => {
              const data = resp.data
              if (data.action === 'POST') {
                this.postData(data)
              } else if (data.action === 'GET') {
                location.href = data.url
              }
            })
            .catch(() => {
              this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
              this.isLoading = false
            })
        })
        .catch((err) =>{
          console.log(err.response.data)
          if (err.response.data.message) {
            this.$store.commit('message/showMessage', ['error', err.response.data.message])
          } else {
            this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
          }
          this.isLoading = false
        })
    },
    postData (data) {
      let form = document.createElement('form')
      form.method = 'POST'
      form.action = data.url
      Object.keys(data.payload).forEach(k => {
        let el = document.createElement('input')
        el.name = k
        el.value = data.payload[k]
        form.appendChild(el)
      })
      document.body.appendChild(form)
      form.submit()
    },
    proceedManual () {
      this.$router.push(`/checkout/${this.lead.pid}/bank_transfer`)
    },
    proceedCod () {
      this.axios.patch(`/leads/${this.lead.pid}/`, this.payment.data)
        .then((response) => {
          const lead = response.data
          let storeUrl = `${process.env.VUE_APP_YEZZA_CHECKOUT_URL}/orders/${lead.pid}`
          let message = `Cash On Delivery details:%0d%0a%0d%0a${encodeURIComponent(`Order Form: ${storeUrl}`)}%0d%0a%0d%0a`
          let productMessage = ''
          let count = 1
          lead.products.forEach(prod => {
            productMessage = productMessage.concat(`${count}. ${prod.name} (x${prod.quantity})%0d%0a`)
            count ++
          })
          message = message.concat(`${productMessage}%0d%0a`)
          let customer = `*Customer:*%0d%0aName: ${lead.name}%0d%0aPhone: ${lead.phone}%0d%0a%0d%0a`
          message = message.concat(customer)
          if (lead.shipping_address) {
            let addr2 = ''
            let areasuburb = ''
            if (lead.shipping_address.address_2) {
              addr2 = `%0d%0a${lead.shipping_address.address_2},`
            }
            if (lead.shipping_address.area) {
              areasuburb = ` ${lead.shipping_address.area},%0d%0a${lead.shipping_address.suburb}`
            }
            let address = `*Address:*%0d%0a${lead.shipping_address.address_1},${addr2}%0d%0a${lead.shipping_address.postcode},${areasuburb} ${lead.shipping_address.city},%0d%0a${lead.shipping_address.state}, ${lead.shipping_address.country}.%0d%0a%0d%0a`
            message = message.concat(address)
          }
          let payment = `Payment: COD%0d%0aAmount: *${this.$options.filters.displayPrice(this.$store.state.current.order.price.total, this.$currency)}*`
          message = message.concat(payment)
          
          location.href = `https://api.whatsapp.com/send?phone=${lead.member.phone}&text=${message}`
        })
        .catch((e) => {
          console.error(e)
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
    },
    proceedPas () {
      this.axios.patch(`/leads/${this.lead.pid}/`, this.payment.data)
        .then((response) => {
          const lead = response.data
          let storeUrl = `${process.env.VUE_APP_YEZZA_CHECKOUT_URL}/orders/${lead.pid}`
          let message = `Hi, I would like to pay at shop.%0d%0a%0d%0a${encodeURIComponent(`Order Form: ${storeUrl}`)}%0d%0a%0d%0a`
          let productMessage = ''
          let count = 1
          lead.products.forEach(prod => {
            productMessage = productMessage.concat(`${count}. ${prod.name} (x${prod.quantity})%0d%0a`)
            count ++
          })
          message = message.concat(`${productMessage}%0d%0a`)
          let customer = `*Customer:*%0d%0aName: ${lead.name}%0d%0aPhone: ${lead.phone}%0d%0a%0d%0a`
          message = message.concat(customer)
          let payment = `Payment: Pay at store%0d%0aAmount: *${this.$options.filters.displayPrice(this.$store.state.current.order.price.total, this.$currency)}*`
          message = message.concat(payment)
          
          location.href = `https://api.whatsapp.com/send?phone=${lead.member.phone}&text=${message}`
        })
        .catch(() => {
          this.showMessage(['error', 'Unable to complete your request. Please try again later'])
        })
    }
  }
}
</script>

<style scoped>
.v-sheet--outlined {
  border: thin solid var(--v-basic300) !important;
}
.leave-message >>> textarea::placeholder {
  color: var(--v-primary400);
  opacity: 1;
}
</style>